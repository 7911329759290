<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <flow-side-bar
      :flow-data="flowData"
      :flow-sidebar-active.sync="flowSidebarActive"
      :icons="icons"
      @refetch-data="refetchFlowsData"
    />
    <template>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <template>
            <!-- Table Container Card -->
            <b-card
              no-body
            >
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Entries</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <!-- Add -->
                  <b-col
                    class="d-flex align-items-center justify-content-end"
                    cols="12"
                    md="6"
                  >
                    <b-button
                      v-if="$can('create', 'flows')"
                      variant="primary"
                      @click="flowSidebarActive = true"
                    >
                      Toevoegen
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-table
                ref="refFlowsListTable"
                :items="fetchFlows"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                show-empty
                empty-text="Geen overeenkomende records gevonden"
                class="position-relative categorie-table"
              >
                <!-- Column: Id -->
                <template #cell(id)="data">
                  <b-link
                    class="font-weight-bold"
                    @click="editFlow(data.value)"
                  >
                    #{{ data.value }}
                  </b-link>
                </template>

                <!-- Column: Category -->
                <template #cell(name)="data">
                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-top: 8px"
                    @click="editFlow(data.item.id)"
                  >
                    <b-media vertical-align="center">
                      <template #aside>
                        <a
                          data-v-4b8414c1=""
                          href="/"
                          class="b-avatar router-link-active badge-secondary rounded-circle"
                          target="_self"
                          style="width: 32px; height: 32px;"
                        >
                          <span
                            class="b-avatar-text"
                            style="font-size: calc(12.8px);"
                          >
                            <span
                              style="font-size: 15px"
                              :class="data.item.icon"
                            />
                          </span>
                        </a>
                      </template>
                      {{ data.item.name }}

                    </b-media>
                  </b-link>
                </template>

                <template #cell(canOfferEmail)="data">
                  {{ data.value === 1 ? 'ja' : 'Nee' }}
                </template>

                <template #cell(canSignSignature)="data">
                  {{ data.value === 1 ? 'ja' : 'Nee' }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">

                  <div class="text-nowrap">
                    <b-link
                      :id="`flows-view-action-row-${data.item.id}`"
                      @click="editFlow(data.item.id)"
                    >
                      <feather-icon
                        :id="`flows-row-${data.item.id}-preview-icon`"
                        class="cursor-pointer mx-1 feather-hover"
                        icon="EyeIcon"
                        size="16"
                      />
                    </b-link>
                    <b-tooltip
                      :target="`flows-view-action-row-${data.item.id}`"
                      placement="left"
                    >
                      <p class="mb-0">
                        Aanpassen
                      </p>
                    </b-tooltip>
                  </div>
                </template>
              </b-table>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BLink,
  BMedia,
  BOverlay,
  BTooltip, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useFlowsList from '@/views/pages/flows/useFlowsList'
import FlowSideBar from '@/views/pages/flows/sidebar/FlowSideBar.vue'
import flowsStoreModule from '@/views/pages/flows/flowsStoreModule'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BMedia,
    BLink,
    vSelect,
    BOverlay,
    BTooltip,
    FlowSideBar,
    BButton,
  },
  data() {
    return {
      flowData: {
        id: null,
        name: '',
        icon: '',
        canOfferEmail: 0,
        canSignSignature: 0,
      },
    }
  },
  methods: {
    editFlow(id) {
      store.dispatch('app-flows/fetchFlow', id)
        .then(res => {
          this.flowData = res.data
          this.flowSidebarActive = true
        })
        .catch(() => {
          this.userData = undefined
          this.showUploadLoader = false
          this.$swal({
            icon: 'error',
            title: 'Flow',
            text: `${errorDefaultMessage.fetchDataError}`,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    confirmProductCategoryDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-list/deleteProductCategory', { id })
            .then(() => {
              this.refetchFlowsData()
              this.$swal({
                icon: 'success',
                title: 'Verwijderd!',
                text: 'De productcategorie is verwijderd.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const FLOWS_APP_STORE_MODULE_NAME = 'app-flows'

    // Register module
    if (!store.hasModule(FLOWS_APP_STORE_MODULE_NAME)) store.registerModule(FLOWS_APP_STORE_MODULE_NAME, flowsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLOWS_APP_STORE_MODULE_NAME)) store.unregisterModule(FLOWS_APP_STORE_MODULE_NAME)
    })

    const flowSidebarActive = ref(false)

    const {
      fetchFlows,
      tableColumns,
      perPage,
      currentPage,
      totalFlows,
      dataMeta,
      perPageOptions,
      sortBy,
      refFlowsListTable,
      isSortDirDesc,
      refetchFlowsData,
      showOverlay,
      icons,
    } = useFlowsList()

    return {
      flowSidebarActive,
      fetchFlows,
      tableColumns,
      perPage,
      currentPage,
      totalFlows,
      dataMeta,
      perPageOptions,
      sortBy,
      refFlowsListTable,
      isSortDirDesc,
      refetchFlowsData,
      avatarText,
      showOverlay,
      icons,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }

  .categorie-table{
    .description{
      width:30%;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
