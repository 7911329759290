import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useFlowsList() {
  // Use toast
  const toast = useToast()
  const refFlowsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: false },
    { label: 'Naam', key: 'name', sortable: false },
    { label: 'Offer Email', key: 'canOfferEmail', sortable: false },
    { label: 'Handtekening', key: 'canSignSignature', sortable: false },
    { label: 'Acties', key: 'actions', sortable: false },
  ]
  const perPage = ref(10)
  const totalFlows = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const showOverlay = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refFlowsListTable.value ? refFlowsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFlows.value,
    }
  })

  const refetchFlowsData = () => {
    refFlowsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchFlowsData()
  })

  const fetchFlows = (ctx, callback) => {
    store
      .dispatch('app-flows/fetchFlows', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalFlows.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen flows',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  const icons = [
    {
      name: 'Offerte',
      code: 'fas fa-cart-plus',
    },
    {
      name: 'Overeenkomst',
      code: 'fas fa-signature',
    },
  ]

  return {
    fetchFlows,
    tableColumns,
    perPage,
    currentPage,
    totalFlows,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refFlowsListTable,
    refetchFlowsData,
    showOverlay,
    icons,
  }
}
