<template>
  <b-sidebar
    id="flow-sidebar"
    :visible="flowSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:flow-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Flow aanmaken
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name-->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Naam"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="localFlowData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="demo-inline-spacing mb-1">
            <b-form-checkbox
              v-model="localFlowData.canOfferEmail"
              switch
              inline
              :value="1"
              :unchecked-value="0"
            >
              Acceptatie E-mail
            </b-form-checkbox>
          </div>

          <div class="demo-inline-spacing mb-1">
            <b-form-checkbox
              v-model="localFlowData.canSignSignature"
              switch
              inline
              :value="1"
              :unchecked-value="0"
            >
              Natte handtekening (directe acceptatie)
            </b-form-checkbox>
          </div>

          <validation-provider
            #default="validationContext"
            name="icons"
            rules="required"
          >
            <b-form-group
              label="Icoon"
              label-for="icons"
            >
              <v-select
                name="icons"
                id="icons"
                :options="icons"
                v-model="localFlowData.icon"
                label="name"
                :reduce="val => val.code"
                dense
                solo
                clearable
              >
                <template slot="selected-option" slot-scope="option">
                  <div class="flex">
                    <b-row>
                      <b-col cols="1">
                        <span style="font-size: 15px" :class="option.code"></span>
                      </b-col>
                      <b-col cols="6">
                        {{ option.name }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="flex">
                    <b-row>
                      <b-col cols="1">
                        <span style="font-size: 15px" :class="option.code"></span>
                      </b-col>
                      <b-col cols="6">
                        {{ option.name }}
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="!localFlowData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Aanmaken
            </b-button>
            <b-button
              v-if="localFlowData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="updateFlow()"
            >
              Aanpassen
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuleren
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BSidebar,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BCol,
    BRow,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'flowSidebarActive',
    event: 'update:flow-sidebar-active',
  },
  props: {
    flowData: {
      type: Object,
      required: true,
    },
    flowSidebarActive: {
      type: Boolean,
      required: true,
    },
    icons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      integer,
      localFlowData: {
        id: null,
        name: '',
        icon: '',
        canOfferEmail: 0,
        canSignSignature: 0,
      },
      blankFlowData: {
        id: null,
        name: '',
        icon: '',
        canOfferEmail: 0,
        canSignSignature: 0,
      },
    }
  },
  watch: {
    flowData: {
      deep: true,
      handler(data) {
        this.localFlowData = data
      },
    },
  },
  methods: {
    onSubmit() {
      store.dispatch('app-flows/addFlow', this.localFlowData)
        .then(() => {
          this.resetFlowData()
          this.$emit('refetch-data')
          this.$emit('update:flow-sidebar-active', false)
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Flow',
            text: `${errorDefaultMessage.saveDataError}`,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    updateFlow() {
      store.dispatch('app-flows/updateFlow', this.localFlowData)
        .then(() => {
          this.resetFlowData()
          this.$emit('refetch-data')
          this.$emit('update:flow-sidebar-active', false)
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Flow',
            text: `${errorDefaultMessage.updateDataError}`,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    resetFlowData() {
      this.localFlowData = JSON.parse(JSON.stringify(this.blankFlowData))
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-product-category-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
