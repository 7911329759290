import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFlows(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}flows`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFlow(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}flows/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFlow(ctx, categoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}flows`, categoryData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFlow(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}flows/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchClientByNumber(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}relations/clientnumbersearch`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
